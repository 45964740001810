import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/my-profile",
      component: () => import("@/view/layout/Layout"),
      children: [

        // Members

        {
          path: "/members/transfer",
          name: "Members Transfer",
          component: () => import("@/view/pages/erp/member-transfer/MembersTransfer.vue")
        },
        {
          path: "/members/list",
          name: "Members List",
          component: () => import("@/view/pages/erp/members/MembershipList.vue")
        },
        {
          path: "/members/search",
          name: "Members Search",
          component: () => import("@/view/pages/erp/members/MemberSearch.vue")
        },
        {
          path: "/members/id-card/report",
          name: "ID Card Report",
          component: () => import("@/view/pages/erp/members/IdCardMembershipList.vue")
        },
        // Member Individual Details
        {
          name: "Member Details",
          path: "member-individual",
          component: () => import("@/view/pages/erp/members/member-individual/MemberIndividual.vue")
        },

        // city

        {
          path: "/city/list",
          name: "City List",
          component: () => import("@/view/pages/erp/city/CityList.vue")
        },

        // Staff

        {
          path: "/staff/list",
          name: "Staff List",
          component: () => import("@/view/pages/erp/staff/StaffList.vue")
        },

        // LGB Members List

        {
          path: "/team-members/lgb/list",
          name: "LGB Members List",
          component: () => import("@/view/pages/erp/team-members/lgb/LgbTeamMembersList.vue")
        },

        // NGB Members List

        {
          path: "/team-members/ngb/list",
          name: "NGB Members List",
          component: () => import("@/view/pages/erp/team-members/ngb/NgbTeamMembersList.vue")
        },

        // ZGB Members List

        {
          path: "/team-members/zgb/list",
          name: "ZGB Members List",
          component: () => import("@/view/pages/erp/team-members/zgb/ZgbTeamMembersList.vue")
        },

        // Current ZGB Members List

        {
          path: "/team-members/current-zgb/list",
          name: "ZGB Members List",
          component: () => import("@/view/pages/erp/team-members/zgb/CurrentZgbTeamMembersList.vue")
        },

        // Current SMA NGB Members List

        {
          path: "/sma-team-members/current-ngb/list",
          name: "SMA NGB List",
          component: () => import("@/view/pages/erp/team-members/sma-ngb/SMANgbTeamMembersList.vue")
        },

        // Current SMA ZGB Members List

        {
          path: "/sma-team-members/current-zgb/list",
          name: "SMA ZGB List",
          component: () => import("@/view/pages/erp/team-members/sma-zgb/SMAZgbTeamMembersList.vue")
        },
        //SMA Members List
        {
          path: "/sma-plans/list",
          name: "SMA Plans List",
          component: () => import("@/view/pages/erp/team-members/sma-plan/SmaPlansList.vue")
        },

        // Profile
        {
          path: "/my-profile",
          name: "My Profile",
          component: () => import("@/view/pages/profile/MyProfile4.vue")
        },
        {
          path: "/quick-profile-update",
          name: "Quick Profile Update",
          component: () => import("@/view/pages/erp/QuickProfileUpdate.vue")
        },

        // Dashboard

        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },

        // LOM

        {
          path: "/lom/list",
          name: "LO List",
          component: () => import("@/view/pages/erp/lom/LomList.vue")
        },
        {
          path: "/lom/create",
          name: "LO Create",
          component: () => import("@/view/pages/erp/lom/ManualNewLomCreate.vue")
        },
        {
          path: "/lom/details",
          name: "LO Details",
          component: () => import("@/view/pages/erp/lom/lom-individual/LomIndividual.vue")
        },
        {
          path: "/my-lom/details",
          name: "My LO Details",
          component: () => import("@/view/pages/erp/lom/lom-individual/MyLomIndividual.vue")
        },
        {
          path: "/lom/membership-list",
          name: "Membership List",
          component: () => import("@/view/pages/erp/lom/LomMembershipList.vue")
        },

        // New Lo Title

        {
          path: "/new-lo-title/list",
          name: "New LO extension page",
          component: () => import("@/view/pages/erp/new_lo_title/NewLoTitleList.vue")
        },
        {
          path: "/new-lo-title/create",
          name: " New Title Clearance",
          component: () => import("@/view/pages/erp/new_lo_title/NewLoTitleCreate.vue")
        },
        {
          path: "/new-lo/subscription-payment",
          name: "Extension of New LO",
          component: () => import("@/view/pages/erp/new_lo_title/NewLoSubscriptionPayment.vue")
        },

        // Senior members

        {
          path: "/senior/members/list",
          name: "JCI India Senior Members List",
          component: () => import("@/view/pages/erp/seniors/SeniorMembersList.vue")
        },

        // Events Bidding
        {
          path: "/events-bidding/list",
          name: "Events Bidding List",
          component: () => import("@/view/pages/erp/events-bidding/EventsBiddingList.vue")
        },
        {
          path: "/events-bidding/create",
          name: "Events Bidding Create",
          component: () => import("@/view/pages/erp/events-bidding/EventBiddingCreate.vue")
        },

        // LO Bid Event List
        {
          path: "/events-bidding/lo-list",
          name: "Bid Event List",
          component: () => import("@/view/pages/erp/events-bidding/EventsBidList.vue")
        },

        // Senior members Zoon Chairman

        {
          path: "/zone/senior/members/list",
          name: "JCI India Senior Members List",
          component: () => import("@/view/pages/erp/seniors/ZoneSeniorMembersList.vue")
        },

        // LOM President Lists

        {
          path: "/lom/president/list",
          name: "LO President List",
          component: () => import("@/view/pages/erp/lom-president/LomPresidentList.vue")
        },

        // Zone President List

        {
          path: "/zone/president/list",
          name: "Zone President List",
          component: () => import("@/view/pages/erp/zone-president/ZonePresidentList.vue")
        },

        // Members Payment

        {
          path: "/members/subscription/payment/create",
          name: "Subscription Payment",
          component: () => import("@/view/pages/erp/payment/MemberSubscriptionPaymentCreate.vue")
        },

        // Members Payment Checkout

        {
          path: "/members/subscription/payment/checkout",
          name: "Subscription Checkout",
          component: () => import("@/view/pages/erp/payment/MemberSubscriptionPaymentCheckout.vue")
        },

        // Members Subscription List

        {
          path: "/members/subscription/list",
          name: "Subscription List",
          component: () => import("@/view/pages/erp/payment/MemberSubscriptionList.vue")
        },

        // Assign Members to subscription
        {
          path: "/members/subscription/assign",
          name: "Assign Subscription",
          component: () => import("@/view/pages/erp/payment/MemberSubscriptionAssign.vue")
        },

        // Menu List

        {
          path: "/menu/lists",
          name: "Menu List",
          component: () => import("@/view/pages/erp/menu/MenuList.vue")
        },

        // Menu List

        {
          path: "/menu-permission/lists",
          name: "Menu Permission List",
          component: () => import("@/view/pages/erp/menu/MenuPermissionList2.vue")
        },
        {
          path: "/jcom-menu-permission/lists",
          name: "Jcom Menu Permission List",
          component: () => import("@/view/pages/erp/menu/JcomMenuPermissionList.vue")
        },


        // Members Payment

        {
          path: "/members/subscription/payment/offline",
          name: "Manual Subscription Payment",
          component: () => import("@/view/pages/erp/payment/OfflineMemberSubscriptionPayment.vue")
        },

        // Subscription List by LOM

        {
          path: "/lom/subscription/list",
          name: "LO Subscription List",
          component: () => import("@/view/pages/erp/payment/LomSubscriptionList.vue")
        },

        // LOM Manual Member Upload

        {
          path: "/lom/members/upload",
          name: "LO Members Upload",
          component: () => import("@/view/pages/erp/lom/LomMembersUpload.vue")
        },

        // LOM Membership status

        {
          path: "/lom/membership-status/list",
          name: "LO Membership Status List",
          component: () => import("@/view/pages/erp/lom/LomMembershipStatusList.vue")
        },

        // LOM Membership List

        {
          path: "/reports/lo-membership-list",
          name: "LO Membership List",
          component: () => import("@/view/pages/erp/reports/LomMembershipReport.vue")
        },

        // New LGB Members List

        {
          path: "/team-members/new-lgb/list",
          name: "LO Directory Information List",
          component: () => import("@/view/pages/erp/team-members/new-lgb/NewLgbTeamMembersList.vue")
        },

        // Current LGB Members List

        {
          path: "/team-members/current-lgb/list",
          name: "LGB Members List",
          component: () => import("@/view/pages/erp/team-members/lgb/CurrentLgbTeamMembersList.vue")
        },

        // New ZGB Members List

        {
          path: "/team-members/new-zgb/list",
          name: "Zone Directory Information List",
          component: () => import("@/view/pages/erp/team-members/new-zgb/NewZgbTeamMembersList.vue")
        },

        // New NGB Members List

        {
          path: "/team-members/new-ngb/list",
          name: "National Directory Information List",
          component: () => import("@/view/pages/erp/team-members/new-ngb/NewNgbTeamMembersList.vue")
        },

        {
          path: "/access-denied",
          name: "Access Denied",
          component: () => import("@/view/pages/AccessDenied.vue")
        },

        {
          path: "/page/failed",
          name: "Failed",
          component: () => import("@/view/pages/Failed.vue")
        },

        {
          path: "/payment/success",
          name: "Success",
          component: () => import("@/view/pages/PaymentSuccess.vue")
        },

        {
          path: "/order/success",
          name: "Success",
          component: () => import("@/view/pages/OrderSuccess.vue")
        },

        // Subscription Type

        {
          path: "/subsription/type/list",
          name: "SubscriptionTypeList",
          component: () => import("@/view/pages/erp/subscription-type/SubscriptionTypeList.vue")
        },

        // Receipt List

        {
          path: "/receipt/list",
          name: "Receipts",
          component: () => import("@/view/pages/erp/receipt/ReceiptList.vue")
        },

        // Users List

        {
          path: "/users/list",
          name: "Users List",
          component: () => import("@/view/pages/erp/users/UsersList.vue")
        },
        {
          path: "/users/password-reset",
          name: "Reset Users Password",
          component: () => import("@/view/pages/erp/users/UsersPasswordReset.vue")
        },

        // Dashboard

        {
          path: "/dashboard/lom-president",
          name: "Dashboard",
          component: () => import("@/view/pages/erp/dashboard/DashboardLomPresident.vue")
        },
        {
          path: "/dashboard/zone-president",
          name: "Dashboard",
          component: () => import("@/view/pages/erp/dashboard/DashboardZonePresident.vue")
        },
        {
          path: "/dashboard/national-president",
          name: "Dashboard",
          component: () => import("@/view/pages/erp/dashboard/DashboardNationalPresident.vue")
        },
        {
          path: "/dashboard/default",
          name: "Dashboard",
          component: () => import("@/view/pages/erp/dashboard/DashboardDefault.vue")
        },
        {
          path: "/dashboard/sma-zone-chairman",
          name: "Dashboard",
          component: () => import("@/view/pages/erp/dashboard/DashboardSMAZoneChairman.vue")
        },
        {
          path: "/dashboard/sma-national-chairman",
          name: "Dashboard",
          component: () => import("@/view/pages/erp/dashboard/DashboardSMANationalChairman.vue")
        },

        // Tutorials

        {
          path: "/pages/tutorials",
          name: "Tutorials",
          component: () => import("@/view/pages/Tutorials.vue")
        },

        // Reports

        {
          path: "/reports/payment/membership-subscription",
          name: "Payment Receipts",
          component: () => import("@/view/pages/erp/reports/SubscriptionPaymentReport.vue")
        },
        {
          path: "/lmc/title/payment-report",
          name: "Title Contribution Payment Report",
          component: () => import("@/view/pages/erp/reports/FoundationPayment/FoundationPaymentReport.vue")
        },
        {
          path: "/reports/new-rejoin-members",
          name: "New / Rejoin Members",
          component: () => import("@/view/pages/erp/reports/NewMembersReport.vue")
        },
        {
          path: "/reports/directory-information",
          name: "DIS Report",
          component: () => import("@/view/pages/erp/reports/DirectoryInformationReport.vue")
        },
        {
          path: "/reports/efficiency-report",
          name: "100% Efficiency Report",
          component: () => import("@/view/pages/erp/reports/EfficiencyReport.vue")
        },
        {
          path: "/reports/mrf-report",
          name: "MRF Report",
          component: () => import("@/view/pages/erp/reports/MrfReport.vue")
        },
        {
          path: "/reports/lom-events-report",
          name: "LO Events Report",
          component: () => import("@/view/pages/erp/reports/LomEventsReport.vue")
        },
        {
          path: "/reports/efficiency-status",
          name: "100% Efficiency Status",
          component: () => import("@/view/pages/erp/reports/LoEfficiencyStatus.vue")
        },
        {
          path: "/reports/lo-subscription",
          name: "LO Subscription Report",
          component: () => import("@/view/pages/erp/reports/LoSubscriptionReport.vue")
        },
        {
          path: "/reports/consolidate-members-report",
          name: "Consolidate Members Report",
          component: () => import("@/view/pages/erp/reports/ConsolidateMembersReport.vue")
        },
        {
          path: "/reports/lo-all-members",
          name: "All Members Of LO",
          component: () => import("@/view/pages/erp/reports/LoAllMembers.vue")
        },
        {
          path: "/reports/new-lo-title/payment",
          name: "Provisional Affiliation",
          component: () => import("@/view/pages/erp/new_lo_title/NewLoTitlePaymentReport.vue")
        },
        {
          path: "/reports/foundation-statistics",
          name: "Foundation Statistics",
          component: () => import("@/view/pages/erp/reports/FoundationPayment/FoundationStatistics.vue")
        },
        {
          path: "/reports/foundation-consolidate-report",
          name: "Foundation Consolidate Report",
          component: () => import("@/view/pages/erp/reports/FoundationPayment/FoundationConsolidateReport.vue")
        },
        {
          path: "/reports/member-statistics",
          name: "Member Statistics",
          component: () => import("@/view/pages/erp/reports/MemberStatistics.vue")
        },
        {
          path: "/reports/jcom-payment",
          name: "JCOM Payments Report",
          component: () => import("@/view/pages/erp/reports/jcom-report/JcomPaymentReport.vue")
        },
        {
          path: "/reports/members-list",
          name: "Members List",
          component: () => import("@/view/pages/erp/reports/MembersList.vue")
        },

        // Foundation

        {
          path: "/lmc/title/contribution-payment",
          name: "Title Contribution Payment",
          component: () => import("@/view/pages/erp/foundation/payment/OfflineFoundationDonationPayment.vue")
        },

        {
          path: "/lmc/title/add-online-payment",
          name: "LMC Online Payment",
          component: () => import("@/view/pages/erp/foundation/payment/AddOnlinePayment.vue")
        },

        {
          path: "/lmc/member/edit",
          name: "LMC Member",
          component: () => import("@/view/pages/erp/foundation/FoundationMemberEdit.vue")
        },

        // Foundation Title

        {
          path: "/foundation-title/list",
          name: "Foundation Title List",
          component: () => import("@/view/pages/erp/foundation/titles/FoundationTitleList.vue")
        },

        // NATCON Event

        {
          path: "/event/payment/list",
          name: "Event Payments Lists",
          component: () => import("@/view/pages/erp/events/natcon/NatconPaymentList.vue")
        },

        {
          path: "/event/online-payment/add",
          name: "Add Online Payments",
          component: () => import("@/view/pages/erp/events/payment/AddEventsOnlinePayment.vue")
        },

        // NATCON Payment Create

        {
          path: "/natcon/payment/create",
          name: "NATCON Registration for LO President",
          component: () => import("@/view/pages/erp/events/natcon/NatconPaymentCreate.vue")
        },

        // NATCON Member Payment Create

        {
          path: "/natcon/member-payment/create",
          name: "NATCON Registration for Member",
          component: () => import("@/view/pages/erp/events/natcon/NatconMemberPaymentCreate.vue")
        },

        // NATCON Non-Member Payment Create

        {
          path: "/natcon/non-member-payment/create",
          name: "NATCON Registration for Non-Member",
          component: () => import("@/view/pages/erp/events/natcon/NatconNonMemberPaymentCreate.vue")
        },

        {
          path: "/sma-natcon/member/registration-create",
          name: "SMA NATCON Registration",
          component: () => import("@/view/pages/erp/events/sma-natcon/SmaNatconMemberRegistrationCreate.vue")
        },

        // ASPAC Registration

        {
          path: "/aspac/registration/member",
          name: "ASPAC Registration for member",
          component: () => import("@/view/pages/erp/events/aspac/AspacJciMemberRegistrationCreate.vue")
        },
        {
          path: "/aspac/registration/lo-president",
          name: "ASPAC Registration for LO President",
          component: () => import("@/view/pages/erp/events/aspac/AspacLoPresidentRegistrationCreate.vue")
        },
        {
          path: "/aspac/registration/zone-officer",
          name: "ASPAC Registration for Zone Officer",
          component: () => import("@/view/pages/erp/events/aspac/AspacZoneOfficerRegistrationCreate.vue")
        },


        // All MRF Events lists

        {
          path: "/events/list",
          name: "Events Lists",
          component: () => import("@/view/pages/erp/events/AllEventsList.vue")
        },

        // All Events List

        {
          path: "/events/setting",
          name: "Events Setting",
          component: () => import("@/view/pages/erp/events/all-events/EventsList.vue")
        },

        {
          path: "/events/edit",
          name: "Events Edit",
          component: () => import("@/view/pages/erp/events/all-events/EventsEdit.vue")
        },


        // Events Create

        {
          path: "/events/create",
          name: "Events Create",
          component: () => import("@/view/pages/erp/events/EventsCreate.vue")
        },

        // LOM Events Month List

        {
          path: "/lom-events/month-list",
          name: "LO Events",
          component: () => import("@/view/pages/erp/events/lom-events/LomEventsMonthList.vue")
        },

        // LOM Events Month List

        {
          path: "/lom-events/list",
          name: "LO Events",
          component: () => import("@/view/pages/erp/events/lom-events/LomEventsList.vue")
        },

        // LOM Events Create

        {
          path: "/lom-events/create",
          name: "LO Events",
          component: () => import("@/view/pages/erp/events/lom-events/LomEventsCreate.vue")
        },

        // LOM Events Edit

        {
          path: "/lom-events/edit",
          name: "LO Events",
          component: () => import("@/view/pages/erp/events/lom-events/LomEventsEdit.vue")
        },

        // Events Edit

        {
          path: "/events/edit",
          name: "Events Edit",
          component: () => import("@/view/pages/erp/events/EventsEdit.vue")
        },

        // LO Training Qusetion List

        {
          path: "/lom-training-question/list",
          name: "LDMT/LAV Training Question List'",
          component: () => import("@/view/pages/erp/events/lom-training-question/LomTrainingQuestionList.vue")
        },

        // Events Scored List

        {
          path: "/events/score/list",
          name: "Events Scored Lists",
          component: () => import("@/view/pages/erp/events-score/EventsScoredList.vue")
        },

        // Efficiency LOM Status List

        {
          path: "/efficiency/lom-status",
          name: "100% Efficiency Status",
          component: () => import("@/view/pages/erp/events-score/EfficiencyLomStatus.vue")
        },

        // MRF Events Create

        {
          path: "/mrf-events/create",
          name: "Manual MRF Create",
          component: () => import("@/view/pages/erp/events/mrf-events/MrfEventsCreate.vue")
        },

        // MRF Events Edit

        {
          path: "/mrf-events/edit",
          name: "Manual MRF Edit",
          component: () => import("@/view/pages/erp/events/mrf-events/MrfEventsEdit.vue")
        },

        // MRF Month

        {
          path: "/mrf-month/lists",
          name: "MRF Deadline Settings",
          component: () => import("@/view/pages/erp/mrf-month/MrfDeadlineList.vue")
        },

        // event registraions list

        {
          path: "/my-registrations/list",
          name: "My Registraions",
          component: () => import("@/view/pages/erp/events/MyRegistraions.vue")
        },

        // program registraions list

        {
          path: "/my-registrations/list-v2",
          name: "My Registraions",
          component: () => import("@/view/pages/erp/jci-program/MyProgramRegistraions.vue")
        },

        // NALANDA event registration

        {
          path: "/nalanda-event/registration",
          name: "NALANDA Event Registration",
          component: () => import("@/view/pages/erp/events/nalanda-events/NalandaEventCreate.vue")
        },

        // ABLE event registration

        {
          path: "/able-event/registration",
          name: "ABLE Event Registration",
          component: () => import("@/view/pages/erp/events/able-events/AbleEventCreate.vue")
        },

        // JCI Event for Member registration

        {
          path: "/jci-event/registration",
          name: "JCI Event Registration",
          component: () => import("@/view/pages/erp/events/jci-event-for-members/JciEventForMembersCreate.vue")
        },

        // ZTWS event registration

        {
          path: "/ztws-event/registration",
          name: "ZTWS Event Registration",
          component: () => import("@/view/pages/erp/events/ztws-events/ZTWSEventCreate.vue")
        },

        // CAPP event registration

        {
          path: "/capp-event/registration",
          name: "National Level CAPP Event Registration",
          component: () => import("@/view/pages/erp/events/capp-events/CappEventCreate.vue")
        },

        // AUTHOR events registration

        {
          path: "/author-event/registration",
          name: "AUTHOR Event Registration",
          component: () => import("@/view/pages/erp/events/author-event/AuthorEventRegistration.vue")
        },

        // NTTTS events registration

        {
          path: "/nttts-event/registration",
          name: "NTTTS Event Registration",
          component: () => import("@/view/pages/erp/events/nttts-event/NtttsEventRegistration.vue")
        },

        // Speech Craft events registration

        {
          path: "/speech-craft-event/registration",
          name: "Speech Craft Event Registration",
          component: () => import("@/view/pages/erp/events/speech-craft-event/SpeechCraftEventCreate.vue")
        },

        // Trainer's Enrichment Program registration

        {
          path: "/trainer-enrichment-event/registration",
          name: "Trainer's Enrichment Program Registration",
          component: () => import("@/view/pages/erp/events/trainer-enrichment/TrainerEnrichMentEventCreate.vue")
        },

        // Buy From a Jaycee

        {
          path: "/buy-from-jci/list",
          name: "Buy From a Jaycee List",
          component: () => import("@/view/pages/erp/buy-from-jci/BuyFromJCIList.vue")
        },

        {
          path: "/buy-from-jci/create",
          name: "Buy from a Jaycee Fortnight form",
          component: () => import("@/view/pages/erp/buy-from-jci/BuyFromJCICreate.vue")
        },

        {
          path: "/report/buy-from-jci",
          name: "Buy From a Jaycee Report",
          component: () => import("@/view/pages/erp/reports/BuyFromJCIReport.vue")
        },

        // JCI India Website Downloads

        {
          path: "/jci-india/downloads/list",
          name: "JCI Forms",
          component: () => import("@/view/pages/erp/app_web/jci-forms/JciFormsList.vue")
        },

        // Assign Zone

        {
          path: "/jci-india/assign/zone",
          name: "Assign Zone",
          component: () => import("@/view/pages/erp/zone/AssignZone.vue")
        },

        // Assign LOM

        {
          path: "/jci-india/assign/lom",
          name: "Assign LO",
          component: () => import("@/view/pages/erp/lom/AssignLom.vue")
        },
        {
          path: "/jci-india/lom/reallocate-zone",
          name: "Assign LO",
          component: () => import("@/view/pages/erp/lom/ReallocateLoZone.vue")
        },

        // Settings List

        {
          path: "/settings/list",
          name: "Settings",
          component: () => import("@/view/pages/erp/settings/SettingsList.vue")
        },

        // Year migration

        {
          path: "/year-migration/list",
          name: "Year migration",
          component: () => import("@/view/pages/erp/YearMigration.vue")
        },

        //Year List

        {
          path: "/jci-year/list",
          name: "Year List",
          component: () => import("@/view/pages/erp/jci-year/JciYearList.vue")
        },

        // App Settings List

        {
          path: "/app/settings/list",
          name: "App Settings",
          component: () => import("@/view/pages/erp/app_web/settings/AppWebSettingsList.vue")
        },

        // Group Photo Approval

        {
          path: "/app/settings/group_photo_approval",
          name: "Group Photo Approved",
          component: () => import("@/view/pages/erp/app_web/GroupPhotoApprove.vue")
        },

        // App Settings List

        {
          path: "/event-sub-type/list",
          name: "Events sub type",
          component: () => import("@/view/pages/erp/event-sub-type/EventSubTypeList.vue")
        },


        // SMA Payment Create

        {
          path: "/senior-members/bulk-payment",
          name: "SMA Bulk Payment",
          component: () => import("@/view/pages/erp/payment/BulkSeniorMemberPayment.vue")
        },

        // SMA Members Subscription List

        {
          path: "/senior-members/receipts/list",
          name: "SMA Receipts List",
          component: () => import("@/view/pages/erp/payment/SeniorMemberReceiptList.vue")
        },

        // SMA Members Subscription List1

        {
          path: "/zone/senior-members/receipts/list",
          name: "SMA Receipts List",
          component: () => import("@/view/pages/erp/payment/ZoneSeniorMemberReceiptList.vue")
        },

        // Assign SMA Members to subscription
        {
          path: "/senior/Members/assign",
          name: "Assign SMA Receipt",
          component: () => import("@/view/pages/erp/payment/SeniorMemberAssign.vue")
        },

        // Assign SMA Members report
        {
          path: "/senior-members/report",
          name: "SMA Members Report",
          component: () => import("@/view/pages/erp/reports/SeniorMemberReport.vue")
        },

        {
          path: "/members/transfer/assign",
          name: "Members Transfer Initiate",
          component: () => import("@/view/pages/erp/member-transfer/initiate/AssignMembersTransfer.vue")
        },
        {
          path: "/lo/members-transfer/confirmation-status-list",
          name: "Members Transfer Status",
          component: () => import("@/view/pages/erp/member-transfer/initiate/MembersTransferConfirmationStatusList.vue")
        },
        {
          path: "/lo/members-transfer/approval-status-list",
          name: "Members Transfer Status",
          component: () => import("@/view/pages/erp/member-transfer/approval/MembersTransferApprovalStatusList.vue")
        },

        // App / Website Team Members List

        {
          path: "/wm-team-members/list",
          name: "App / Website Team Members",
          component: () => import("@/view/pages/erp/app-team-members/AppTeamMembersList.vue")
        },

        // App / Website Team List

        {
          path: "/wm-team/list",
          name: "App / Website Teams",
          component: () => import("@/view/pages/erp/app-team/AppTeamList.vue")
        },

        {
          path: "/wm-team/rearrange",
          name: "App / Website Teams Rearrange",
          component: () => import("@/view/pages/erp/app-team/AppTeamRearrange.vue")
        },

        // National Committee List

        {
          path: "/national-committees/list",
          name: "National Committees",
          component: () => import("@/view/pages/erp/committees/CommitteesList.vue")
        },

        {
          path: "/national-committees/rearrange",
          name: "National Committees Rearrange",
          component: () => import("@/view/pages/erp/committees/CommitteesRearrange.vue")
        },

        // National Committee Members

        {
          path: "/national-committees-members/list",
          name: "National Committees",
          component: () => import("@/view/pages/erp/committee-members/CommitteeMembersList.vue")
        },

        {
          path: "/national-committees-members/rearrange",
          name: "National Committees Rearrange",
          component: () => import("@/view/pages/erp/committee-members/CommitteeMembersRearrange.vue")
        },

        // App / Website Team Members List

        {
          path: "/wm-members/list",
          name: "App / Website Members",
          component: () => import("@/view/pages/erp/app-members/AppMemberList.vue")
        },

        // Communications
        {
          path: "/communications/send/delegates-email",
          name: "Send Email To Delegates",
          component: () => import("@/view/pages/erp/communication/SendDelegatesEmail.vue")
        },
        {
          path: "/communications/send/group-email",
          name: "Send Group Email",
          component: () => import("@/view/pages/erp/communication/SendGroupEmail.vue")
        },

        // Jci India Calendar / Tentative Events
        {
          path: "/tentative-events/list",
          name: "JCI India Calendar",
          component: () => import("@/view/pages/erp/events/tentative-events/TentativeEventsList.vue")
        },

        // Programs
        {
          path: "/programs/lists",
          name: "JCI India Programs",
          component: () => import("@/view/pages/erp/programs/ProgramsList.vue")
        },

        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue")
        },

        // Designation
        {
          path: "/designation/settings",
          name: "Designation Settings",
          component: () => import("@/view/pages/erp/designation/DesignationList.vue")
        },

        // roles
        {
          path: "/roles/settings",
          name: "Roles Settings",
          component: () => import("@/view/pages/erp/roles/RolesList.vue")
        },

        // Area
        {
          path: "/area/lists",
          name: "JCI India Area",
          component: () => import("@/view/pages/erp/area/AreaList.vue")
        },

        // Region
        {
          path: "/region/lists",
          name: "JCI India Region",
          component: () => import("@/view/pages/erp/region/RegionList.vue")
        },

        // category
        {
          path: "/category/lists",
          name: "Category Settings",
          component: () => import("@/view/pages/erp/category/CategoryList.vue")
        },
        {
          path: "/category/edit",
          name: "Category Edit",
          component: () => import("@/view/pages/erp/category/CategoryEdit.vue")
        },

        // module
        {
          path: "/module/lists",
          name: "Module Settings",
          component: () => import("@/view/pages/erp/module/ModuleList.vue")
        },

        // Efficiency Settings
        {
          path: "/yearwise-efficiency/settings",
          name: "100% Efficiency Settings",
          component: () => import("@/view/pages/erp/efficiency/YearwiseEfficiencyList.vue")
        },

        // Criteria Settings
        {
          path: "/criteria/settings",
          name: "Criteria Settings",
          component: () => import("@/view/pages/erp/efficiency/criteria/CriteriaList.vue")
        },

        // Group Settings
        {
          path: "/group/settings",
          name: "Group Settings",
          component: () => import("@/view/pages/erp/group/GroupList.vue")
        },

        // Group Settings
        {
          path: "/group-members/lists",
          name: "Group Members Settings",
          component: () => import("@/view/pages/erp/group-members/GroupMembersList.vue")
        },

        // JCOM Table
        {
          path: "/jcom/table/lists",
          name: "JCOM Table",
          component: () => import("@/view/pages/erp/jcom/jcom-table/JcomTableList.vue")
        },
        {
          path: "/jcom/my-table/lists",
          name: "My JCOM Tables",
          component: () => import("@/view/pages/erp/jcom/jcom-table/MyJcomTableList.vue")
        },
        {
          path: "/jcom/table-assign/",
          name: "",
          component: () => import("@/view/pages/erp/jcom/jcom-table/JcomTableReallocate.vue")
        },

        // JCOM Table Upload
        {
          path: "/jcom/table/upload",
          name: "JCOM Table",
          component: () => import("@/view/pages/erp/jcom/jcom-table/jcomTableUpload.vue")
        },
        // JCOM Growth
        {
          path: "/jcom/growth-report",
          name: "JCOM Growth Report",
          component: () => import("@/view/pages/erp/jcom/jcom_report/JcomGrowthReport.vue")
        },

        //  NP JCOM Report
        {
          path: "/np-jcom/report",
          name: "NP JCOM Report",
          component: () => import("@/view/pages/erp/jcom/jcom_report/NPJcomReport.vue")
        },

        {
          path: "/np-report/jci-member-list",
          name: "Consolidate JCI Member List",
          component: () => import("@/view/pages/erp/reports/NpMemberList.vue"),
        },

        // JCOM Performance
        {
          path: "/jcom/perfomance-report",
          name: "JCOM Perfomance Report",
          component: () => import("@/view/pages/erp/jcom/jcom_report/JcomPerformanceReport.vue")
        },
        // JCOM Dashboard
        {
          path: "/jcom/dashboard",
          name: "JCOM Dashboard",
          component: () => import("@/view/pages/erp/jcom/jcom-dashboard/JcomDashboard.vue")
        },
        // JCOM Attendance Report
        {
          path: "/jcom/attendance-report",
          name: "JCOM Attendance Report",
          component: () => import("@/view/pages/erp/jcom/jcom_report/JcomAttendanceReport.vue")
        },
        // JCOM Connect
        {
          path: "/jcom/connect/lists",
          name: "JCOM Connect",
          component: () => import("@/view/pages/erp/jcom/jcom-connect/JcomConnectList.vue")
        },

        // JCOM G-Note
        {
          path: "/jcom/gnote/lists",
          name: "JCOM G-Note",
          component: () => import("@/view/pages/erp/jcom/jcom-gnote/JcomGnoteList.vue")
        },

        // JCOM National Board Members
        {
          path: "/jcom/national-board-members/lists",
          name: "JCOM National Board Members",
          component: () => import("@/view/pages/erp/jcom/jcom-national-board/JcomNationalBoardMembersList.vue")
        },

        // JCOM Zone Board Members
        {
          path: "/jcom/zone-board-members/lists",
          name: "JCOM Zone Board Members",
          component: () => import("@/view/pages/erp/jcom/jcom-zone-board/JcomZoneBoardMembersList.vue")
        },

        // JCOM Table Board Members
        {
          path: "/jcom/table-board-members/lists",
          name: "JCOM Table Board Members",
          component: () => import("@/view/pages/erp/jcom/jcom-table-board/JcomTableBoardMembersList.vue")
        },

        // JCOM Dashboard
        {
          path: "/jcom/my-dashboard",
          name: "Dashboard",
          component: () => import("@/view/pages/erp/jcom/jcom-dashboard/MyDashboard.vue")
        },

        // JCOM Subscription Payment
        {
          path: "/jcom/subscription-payment/create",
          name: "JCOM Subscription Payment",
          component: () => import("@/view/pages/erp/jcom/jcom-subscription-payment/JcomSubscriptionPaymentCreate.vue")
        },

        // JCOM Table Members
        {
          path: "/jcom/table/members-list",
          name: "JCOM Table Members",
          component: () => import("@/view/pages/erp/jcom/jcom-table-members/JcomTableMembersList.vue")
        },
        {
          path: "/jcom/my-table/members-list",
          name: "My Table Tables",
          component: () => import("@/view/pages/erp/jcom/jcom-table-members/MyJcomTableMembersList.vue")
        },
        // JCOM Table Members
        {
          path: "/jcom/my-table/members-list-v2",
          name: "JCOM Table Members",
          component: () => import("@/view/pages/erp/jcom/jcom-table-members/MyTableMembersListCard.vue")
        },
        // JCOM Table Members Upload
        {
          path: "/jcom/table/members-upload",
          name: "JCOM Table Members Upload",
          component: () => import("@/view/pages/erp/jcom/jcom-table-members/JcomTableMembersUpload.vue")
        },

        //JCOM Plan
        {
          path: "/jcom-plan/list",
          name: "JCOM Plans List",
          component: () => import("@/view/pages/erp/jcom/jcom-plan/JcomPlanList.vue")
        },
        // JCI Program Report
        {
          path: "/jci-event/report",
          name: "JCI Event Report",
          component: () => import("@/view/pages/erp/jci-program/JciProgramReport.vue")
        },
        {
          path: "/jci-event/payment/report",
          name: "Event Payment Report",
          component: () => import("@/view/pages/erp/reports/jci-progrm/JciProgramPaymentReport.vue")
        },

        // JCI Program List
        {
          path: "/jci-event/list",
          name: "National Events",
          component: () => import("@/view/pages/erp/jci-program/JciProgramList.vue")
        },

        // JCI Program Create
        {
          path: "/jci-event/create",
          name: "JCI Event Create",
          component: () => import("@/view/pages/erp/jci-program/JciProgramCreate.vue")
        },

        // JCI Program Edit
        {
          path: "/jci-event/edit",
          name: "JCI Event Edit",
          component: () => import("@/view/pages/erp/jci-program/JciProgramEdit.vue")
        },

        // My Support Tickets
        {
          path: "/support/my-tickets",
          name: "My Support Tickets",
          component: () => import("@/view/pages/erp/my-support-ticket/MySupportTicketList.vue")
        },

        // JCI Support Tickets
        {
          path: "/support/all-tickets",
          name: "All Support Tickets",
          component: () => import("@/view/pages/erp/my-support-ticket/JciSupportTicketList.vue")
        },

        // JCI Trainer

        {
          path: "/jci-trainers/list",
          name: "JCI Trainers List",
          component: () => import("@/view/pages/erp/jci-trainers/JciTrainersList.vue")
        },

        // JCI Trainer Activity

        {
          path: "/reports/trainers-activity",
          name: "Trainers Activity Report",
          component: () => import("@/view/pages/erp/trainers-activity/TrainersActivityList.vue")
        },


        // Award Category List

        {
          path: "/jci-award/award-category",
          name: "Award Category List",
          component: () => import("@/view/pages/erp/jci-award/award-category/AwardCategoryList.vue")
        },

        // Award Type List

        {
          path: "/jci-award/award-type",
          name: "Award Type List",
          component: () => import("@/view/pages/erp/jci-award/award-type/AwardTypeList.vue")
        },

        // Judging Criteria List

        {
          path: "/jci-award/judging-criteria",
          name: "Award Judging Criteria List",
          component: () => import("@/view/pages/erp/jci-award/judging-criteria/JudgingCriteriaList.vue")
        },

        // Judging Criteria Questions List

        {
          path: "/jci-award/judging-criteria-questions",
          name: "Award Judging Criteria Questions List",
          component: () => import("@/view/pages/erp/jci-award/judging-criteria-questions/JudgingCriteriaQuestionsList.vue")
        },

        // Judges List

        {
          path: "/jci-award/judges/list",
          name: "Award Judges List",
          component: () => import("@/view/pages/erp/jci-award/judges/JudgesList.vue")
        },

        // Judges Upload

        {
          path: "/jci-award/judges/upload",
          name: "Award Judges Upload",
          component: () => import("@/view/pages/erp/jci-award/judges/JudgesUpload.vue")
        },

        // Zonecon Lom Award List
        {
          path: "/zone/lom-award/list",
          name: "ZoneCon Lom Award List",
          component: () => import("@/view/pages/erp/jci-award/zonecon/lom/ZoneConLomAwardList.vue")
        },

        // Zonecon Lom Award Create
        {
          path: "/zone/lom-award/create",
          name: "ZoneCon Lom Award Create",
          component: () => import("@/view/pages/erp/jci-award/zonecon/lom/ZoneConLomAwardCreate.vue")
        },

        // Zonecon Lom Award Edit
        {
          path: "/zone/lom-award/edit",
          name: "ZoneCon Lom Award Edit",
          component: () => import("@/view/pages/erp/jci-award/zonecon/lom/ZoneConLomAwardEdit.vue")
        },

        // Zonecon Individual Award List
        {
          path: "/zone/individual-award/list",
          name: "ZoneCon Individual Award List",
          component: () => import("@/view/pages/erp/jci-award/zonecon/individual/ZoneConIndividualAwardList.vue")
        },
        // Zonecon Individual Award Create
        {
          path: "/zone/individual-award/create",
          name: "ZoneCon Individual Award Create",
          component: () => import("@/view/pages/erp/jci-award/zonecon/individual/ZoneConIndividualAwardCreate.vue")
        },

        // Zonecon Individual Award Edit
        {
          path: "/zone/individual-award/edit",
          name: "ZoneCon Individual Award Edit",
          component: () => import("@/view/pages/erp/jci-award/zonecon/individual/ZoneConIndividualAwardEdit.vue")
        },

        // Zonecon Officers Award List
        {
          path: "/zone/officers-award/list",
          name: "ZoneCon Officers Award List",
          component: () => import("@/view/pages/erp/jci-award/zonecon/officers/ZoneConOfficersAwardList.vue")
        },

        // Zonecon Officers Award Create
        {
          path: "/zone/officers-award/create",
          name: "ZoneCon Officers Award Create",
          component: () => import("@/view/pages/erp/jci-award/zonecon/officers/ZoneConOfficersAwardCreate.vue")
        },

        // Zonecon Officers Award Edit
        {
          path: "/zone/officers-award/edit",
          name: "ZoneCon Officers Award Edit",
          component: () => import("@/view/pages/erp/jci-award/zonecon/officers/ZoneConOfficersAwardEdit.vue")
        },

        // Natcon Lom Award List
        {
          path: "/national/lom-award/list",
          name: "NatCon Lom Award List",
          component: () => import("@/view/pages/erp/jci-award/natcon/lom/NatConLomAwardList.vue")
        },

        // Natcon Lom Award Create
        {
          path: "/national/lom-award/create",
          name: "NatCon Lom Award Create",
          component: () => import("@/view/pages/erp/jci-award/natcon/lom/NatConLomAwardCreate.vue")
        },

        // Natcon Lom Award Edit
        {
          path: "/national/lom-award/edit",
          name: "NatCon Lom Award Edit",
          component: () => import("@/view/pages/erp/jci-award/natcon/lom/NatConLomAwardEdit.vue")
        },

        // Natcon Individual Award List
        {
          path: "/national/individual-award/list",
          name: "NatCon Individual Award List",
          component: () => import("@/view/pages/erp/jci-award/natcon/individual/NatConIndividualAwardList.vue")
        },

        // Natcon Individual Award Create
        {
          path: "/national/individual-award/create",
          name: "NatCon Individual Award Create",
          component: () => import("@/view/pages/erp/jci-award/natcon/individual/NatConIndividualAwardCreate.vue")
        },

        // Natcon Individual Award Edit
        {
          path: "/national/individual-award/edit",
          name: "NatCon Individual Award Edit",
          component: () => import("@/view/pages/erp/jci-award/natcon/individual/NatConIndividualAwardEdit.vue")
        },

        // Natcon Officers Award List
        {
          path: "/national/officers-award/list",
          name: "NatCon Officers Award List",
          component: () => import("@/view/pages/erp/jci-award/natcon/officers/NatConOfficersAwardList.vue")
        },

        // Natcon Officers Award Create
        {
          path: "/national/officers-award/create",
          name: "NatCon Officers Award Create",
          component: () => import("@/view/pages/erp/jci-award/natcon/officers/NatConOfficersAwardCreate.vue")
        },

        // Natcon Officers Award Edit
        {
          path: "/national/officers-award/edit",
          name: "NatCon Officers Award Edit",
          component: () => import("@/view/pages/erp/jci-award/natcon/officers/NatConOfficersAwardEdit.vue")
        },

        // NP-Recognition List
        {
          path: "/np-recognition/list",
          name: "NP-Recognition List",
          component: () => import("@/view/pages/erp/jci-award/np-recognition/NpRecognitionList.vue")
        },

        // NP-Recognition Report
        {
          path: "/np-recognition/report",
          name: "NP-Recognition Report",
          component: () => import("@/view/pages/erp/jci-award/np-recognition/NpRecognitionReport.vue")
        },

        // NP-Recognition Category List
        {
          path: "/np-recognition-category/list",
          name: "NP-Recognition Category List",
          component: () => import("@/view/pages/erp/jci-award/np-recognition-category/NpRecognitionCategoryList.vue")
        },

        // Upgrade to SMA member
        {
          path: "/members/upgrade-senior-member",
          name: "Upgrade to SMA Member",
          component: () => import("@/view/pages/erp/seniors/UpgradeToSeniorMember.vue")
        },

        // Zone List
        {
          path: "/zone/lists",
          name: "JCI India Zone ",
          component: () => import("@/view/pages/erp/zone/ZoneList.vue")
        },

        // Awards Report
        {
          path: "/award/report",
          name: "Awards Report",
          component: () => import("@/view/pages/erp/jci-award/AwardsReport.vue")
        },

        // Judgeds Report
        {
          path: "/judge/report",
          name: "Judges Report",
          component: () => import("@/view/pages/erp/jci-award/JudgesReport.vue")
        },

        // Award Score Report
        {
          path: "/award-score/report",
          name: "Award Score Report",
          component: () => import("@/view/pages/erp/jci-award/AwardScoreReport.vue")
        },

        // Zonecon Vice President Award List
        {
          path: "/zone/vice-president-award/list",
          name: "ZoneCon Vice President Award List",
          component: () => import("@/view/pages/erp/jci-award/zonecon/vice-president/ZoneConVicePresidentAwardList.vue")
        },

        // Zonecon Vice President Award Create
        {
          path: "/zone/vice-president-award/create",
          name: "ZoneCon Vice President Award Create",
          component: () => import("@/view/pages/erp/jci-award/zonecon/vice-president/ZoneConVicePresidentAwardCreate.vue")
        },


        // Zonecon Vice President Award Edit
        {
          path: "/zone/vice-president-award/edit",
          name: "ZoneCon Vice President Award Edit",
          component: () => import("@/view/pages/erp/jci-award/zonecon/vice-president/ZoneConVicePresidentAwardEdit.vue")
        },

        // Natcon Vice President Award List
        {
          path: "/national/vice-president-award/list",
          name: "NatCon Vice President Award List",
          component: () => import("@/view/pages/erp/jci-award/natcon/vice-president/NatConVicePresidentAwardList.vue")
        },

        // Natcon Vice President Award Create
        {
          path: "/national/vice-president-award/create",
          name: "NatCon Vice President Award Create",
          component: () => import("@/view/pages/erp/jci-award/natcon/vice-president/NatConVicePresidentAwardCreate.vue")
        },

        // Natcon Vice President Award Edit
        {
          path: "/national/vice-president-award/edit",
          name: "NatCon Vice President Award Edit",
          component: () => import("@/view/pages/erp/jci-award/natcon/vice-president/NatConVicePresidentAwardEdit.vue")
        },

        // Add Mannual Payment to SMA member
        {
          path: "/senior-members/add-manual-payment",
          name: "Add Senior Member Manual Payment",
          component: () => import("@/view/pages/erp/seniors/AddSmaManualPayment.vue")
        },
        // Add Seniors Life Member Mannual Payment
        {
          path: "/senior-life-member/add-manual-payment",
          name: "Add Senior Life Member Manual Payment",
          component: () => import("@/view/pages/erp/seniors/SeniorLifeMemberManualPayment.vue")
        },

        // Seniors Life Membership List
        {
          path: "/senior-life-member/list",
          name: "Senior Life Membership List",
          component: () => import("@/view/pages/erp/seniors/SeniorLifeMembershipList.vue")
        },

        // LO Information List

        {
          path: "/lo-information/list",
          name: "LO Events",
          component: () => import("@/view/pages/erp/lom/lom-information/LomInformation.vue")
        },

        // LO Information Create

        {
          path: "/lom-events/create",
          name: "LO Events",
          component: () => import("@/view/pages/erp/events/lom-events/LomEventsCreate.vue")
        },

        // LO Information Edit

        {
          path: "/lom-events/edit",
          name: "LO Events",
          component: () => import("@/view/pages/erp/events/lom-events/LomEventsEdit.vue")
        },

        // Voting Rights

        {
          path: "/voting-rights",
          name: "Voting Rights",
          component: () => import("@/view/pages/erp/lom/voting-rights/VotingRights.vue")
        },

        {
          path: "report/voting-rights",
          name: "Voting Rights Report",
          component: () => import("@/view/pages/erp/reports/VotingRightsReport.vue")
        },

        //Mission One Lakh
        {
          path: "/mission-one-lakh/dashboard",
          name: "Mission OneLakh",
          component: () => import("@/view/pages/erp/lom/mission-onelakh/MissionOneLakhDashboard.vue")
        },
        {
          path: "/mission-one-lakh/committee/add-member",
          name: "Committee Member List",
          component: () => import("@/view/pages/erp/lom/mission-onelakh/CommitteeMemberList.vue")
        },
        {
          path: "/mission-one-lakh/member-list",
          name: "Members List",
          component: () => import("@/view/pages/erp/lom/mission-onelakh/mol-member/MolMembersList.vue")
        },
        {
          path: "/mission-one-lakh/invited/member-list",
          name: "Potential Members List - Invited",
          component: () => import("@/view/pages/erp/lom/mission-onelakh/mol-member/MolInvitedMembersList.vue")
        },
        {
          path: "/mission-one-lakh/re-invited/member-list",
          name: "Potential Members List - ReInvited",
          component: () => import("@/view/pages/erp/lom/mission-onelakh/mol-member/MolReInvitedMembersList.vue")
        },
        {
          path: "/mission-one-lakh/attended/member-list",
          name: "Potential Members List - Attended",
          component: () => import("@/view/pages/erp/lom/mission-onelakh/mol-member/MolAttendMembersList.vue")
        },
        {
          path: "/mission-one-lakh/join-jci-member-list",
          name: "Potential Members List - Join JCI Member",
          component: () => import("@/view/pages/erp/lom/mission-onelakh/mol-member/JoinJciMembersList.vue")
        },

        //Mission One Lakh Report

        {
          path: "/mission-one-lakh/phase1/committee-member/report",
          name: "Committee Member Report",
          component: () => import("@/view/pages/erp/lom/mission-onelakh/mol-report/MolPhase1Report.vue")
        },

        {
          path: "/mission-one-lakh/phase2/potential-member/report",
          name: "Committee Member Report",
          component: () => import("@/view/pages/erp/lom/mission-onelakh/mol-report/MolPhase2Report.vue")
        },

        //Mission One Lakh Consolidate Report

        {
          path: "/mission-one-lakh/consolidate-report",
          name: "Consolidate Report",
          component: () => import("@/view/pages/erp/lom/mission-onelakh/mol-report/MolConsolidateReport.vue")
        },

        // Member Activity List
        {
          path: "/my-activity/list",
          name: "My Activity List",
          component: () => import("@/view/pages/profile/member-activity/MemberActivityList.vue")
        },

        // Member Activity Create
        {
          path: "/my-activity/create",
          name: "My Activity Create",
          component: () => import("@/view/pages/profile/member-activity/MemberActivityCreate.vue")
        },

        // Member Activity Edit
        {
          path: "/my-activity/edit",
          name: "My Activity Edit",
          component: () => import("@/view/pages/profile/member-activity/MemberActivityEdit.vue")
        },

        // LDMT LOM Status
        {
          path: "/ldmt/lom-status",
          name: "LDMT LO Status",
          component: () => import("@/view/pages/erp/ldmt/LdmtLomStatusList.vue")
        },

        // Consolidate LDMT Report
        {
          path: "/ldmt/consolidate-report",
          name: "LDMT Consolidate Report",
          component: () => import("@/view/pages/erp/ldmt/LdmtConsolidateReport.vue")
        },

        // Send LDMT Report
        {
          path: "/ldmt/send-report",
          name: "Send LDMT Report",
          component: () => import("@/view/pages/erp/ldmt/SendLdmtReport.vue")
        },
        {
          path: "/ldmt/edit-report",
          name: "Edit LDMT Report",
          component: () => import("@/view/pages/erp/ldmt/EditLdmtReport.vue")
        },

        // LAV LOM Status
        {
          path: "/lav/lom-status",
          name: "LAV LO Status",
          component: () => import("@/view/pages/erp/lav/LavLomStatusList.vue")
        },

        // Consolidate LAV Report
        {
          path: "/lav/consolidate-report",
          name: "LAV Consolidate Report",
          component: () => import("@/view/pages/erp/lav/LavConsolidateReport.vue")
        },

        // Send LAV Report
        {
          path: "/lav/send-report",
          name: "Send LAV Report",
          component: () => import("@/view/pages/erp/lav/SendLavReport.vue")
        },
        {
          path: "/lav/edit-report",
          name: "Edit LAV Report",
          component: () => import("@/view/pages/erp/lav/EditLavReport.vue")
        },

        //JCI Training Events
        {
          path: "/training-events/list",
          name: "Training Events",
          component: () => import("@/view/pages/erp/jci-trainingevents/TrainingEventList.vue")
        },
        //LOM Training Setting
        {
          path: "/lom-training-setting/list",
          name: "Lom Training setting",
          component: () => import("@/view/pages/erp/lom-training-setting/LomTrainingSettingList.vue")
        },
        {
          path: "/senior/life-member-plan/list",
          name: "Life Member Plan List",
          component: () => import("@/view/pages/erp/seniors/life_member_plan/SeniorLifeMemberPlanList.vue")
        },

        {
          path: "/lom-training-setting",
          name: "Lom Training setting",
          component: () => import("@/view/pages/erp/lom-training-setting/LomTrainingSettingCreate.vue")
        },

        {
          path: "/vue-bootstrap",
          name: "vue-bootstrap",
          component: () =>
            import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children: [
            {
              path: "alert",
              name: "vue-bootstrap-alert",
              component: () => import("@/view/pages/vue-bootstrap/Alert.vue")
            },
            {
              path: "badge",
              name: "vue-bootstrap-badge",
              component: () => import("@/view/pages/vue-bootstrap/Badge.vue")
            },
            {
              path: "button",
              name: "vue-bootstrap-button",
              component: () => import("@/view/pages/vue-bootstrap/Button.vue")
            },
            {
              path: "button-group",
              name: "vue-bootstrap-button-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonGroup.vue")
            },
            {
              path: "button-toolbar",
              name: "vue-bootstrap-button-toolbar",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonToolbar.vue")
            },
            {
              path: "card",
              name: "vue-bootstrap-card",
              component: () => import("@/view/pages/vue-bootstrap/Card.vue")
            },
            {
              path: "carousel",
              name: "vue-bootstrap-carousel",
              component: () => import("@/view/pages/vue-bootstrap/Carousel.vue")
            },
            {
              path: "collapse",
              name: "vue-bootstrap-collapse",
              component: () => import("@/view/pages/vue-bootstrap/Collapse.vue")
            },
            {
              path: "dropdown",
              name: "vue-bootstrap-dropdown",
              component: () => import("@/view/pages/vue-bootstrap/Dropdown.vue")
            },
            {
              path: "embed",
              name: "vue-bootstrap-embed",
              component: () => import("@/view/pages/vue-bootstrap/Embed.vue")
            },
            {
              path: "form",
              name: "vue-bootstrap-form",
              component: () => import("@/view/pages/vue-bootstrap/Form.vue")
            },
            {
              path: "form-checkbox",
              name: "vue-bootstrap-form-checkbox",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormCheckbox.vue")
            },
            {
              path: "form-file",
              name: "vue-bootstrap-form-file",
              component: () => import("@/view/pages/vue-bootstrap/FormFile.vue")
            },
            {
              path: "form-group",
              name: "vue-bootstrap-form-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormGroup.vue")
            },
            {
              path: "form-input",
              name: "vue-bootstrap-form-input",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormInput.vue")
            },
            {
              path: "form-radio",
              name: "vue-bootstrap-form-radio",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormRadio.vue")
            },
            {
              path: "form-select",
              name: "vue-bootstrap-form-select",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormSelect.vue")
            },
            {
              path: "form-textarea",
              name: "vue-bootstrap-form-textarea",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormTextarea.vue")
            },
            {
              path: "image",
              name: "vue-bootstrap-image",
              component: () => import("@/view/pages/vue-bootstrap/Image.vue")
            },
            {
              path: "input-group",
              name: "vue-bootstrap-input-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/InputGroup.vue")
            },
            {
              path: "jumbotron",
              name: "vue-bootstrap-jumbotron",
              component: () =>
                import("@/view/pages/vue-bootstrap/Jumbotron.vue")
            },
            {
              path: "layout-grid-system",
              name: "vue-bootstrap-layout-grid-system",
              component: () =>
                import("@/view/pages/vue-bootstrap/LayoutGridSystem.vue")
            },
            {
              path: "link",
              name: "vue-bootstrap-link",
              component: () => import("@/view/pages/vue-bootstrap/Link.vue")
            },
            {
              path: "list-group",
              name: "vue-bootstrap-list-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ListGroup.vue")
            },
            {
              path: "media",
              name: "vue-bootstrap-media",
              component: () => import("@/view/pages/vue-bootstrap/Media.vue")
            },
            {
              path: "modal",
              name: "vue-bootstrap-modal",
              component: () => import("@/view/pages/vue-bootstrap/Modal.vue")
            },
            {
              path: "nav",
              name: "vue-bootstrap-nav",
              component: () => import("@/view/pages/vue-bootstrap/Nav.vue")
            },
            {
              path: "navbar",
              name: "vue-bootstrap-navbar",
              component: () => import("@/view/pages/vue-bootstrap/Navbar.vue")
            },
            {
              path: "pagination",
              name: "vue-bootstrap-pagination",
              component: () =>
                import("@/view/pages/vue-bootstrap/Pagination.vue")
            },
            {
              path: "pagination-nav",
              name: "vue-bootstrap-pagination-nav",
              component: () =>
                import("@/view/pages/vue-bootstrap/PaginationNav.vue")
            },
            {
              path: "notify-popover",
              name: "vue-bootstrap-popover",
              component: () => import("@/view/pages/vue-bootstrap/Popover.vue")
            },
            {
              path: "notify-toasts",
              name: "vue-bootstrap-toasts",
              component: () => import("@/view/pages/vue-bootstrap/Toasts.vue")
            },
            {
              path: "notify-tooltip",
              name: "vue-bootstrap-tooltip",
              component: () => import("@/view/pages/vue-bootstrap/Tooltip.vue")
            },
            {
              path: "progress",
              name: "vue-bootstrap-progress",
              component: () => import("@/view/pages/vue-bootstrap/Progress.vue")
            },
            {
              path: "progress-spinner",
              name: "vue-bootstrap-spinner",
              component: () => import("@/view/pages/vue-bootstrap/Spinner.vue")
            },
            {
              path: "table",
              name: "vue-bootstrap-table",
              component: () => import("@/view/pages/vue-bootstrap/Table.vue")
            },
            {
              path: "tabs",
              name: "vue-bootstrap-tabs",
              component: () => import("@/view/pages/vue-bootstrap/Tabs.vue")
            }
          ]
        },
        {
          path: "/vuetify",
          name: "vuetify",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "alerts",
              name: "vuetify-alerts",
              component: () => import("@/view/pages/vuetify/Alerts.vue")
            },
            {
              path: "avatars",
              name: "vuetify-avatars",
              component: () => import("@/view/pages/vuetify/Avatars.vue")
            },
            {
              path: "badges",
              name: "vuetify-badges",
              component: () => import("@/view/pages/vuetify/Badges.vue")
            },
            {
              path: "buttons",
              name: "vuetify-buttons",
              component: () => import("@/view/pages/vuetify/Buttons.vue")
            },
            {
              path: "calendars",
              name: "vuetify-calendars",
              component: () => import("@/view/pages/vuetify/Calendars.vue")
            },
            {
              path: "cards",
              name: "vuetify-cards",
              component: () => import("@/view/pages/vuetify/Cards.vue")
            },
            {
              path: "chips",
              name: "vuetify-chips",
              component: () => import("@/view/pages/vuetify/Chips.vue")
            },
            {
              path: "dialog",
              name: "vuetify-dialog",
              component: () => import("@/view/pages/vuetify/Dialog.vue")
            },
            {
              path: "forms-autocompletes",
              name: "vuetify-autocompletes",
              component: () =>
                import("@/view/pages/vuetify/forms/Autocompletes.vue")
            },
            {
              path: "forms-file-inputs",
              name: "vuetify-file-inputs",
              component: () =>
                import("@/view/pages/vuetify/forms/FileInputs.vue")
            },
            {
              path: "forms",
              name: "vuetify-forms",
              component: () => import("@/view/pages/vuetify/forms/Forms.vue")
            },
            {
              path: "forms-selection-controls",
              name: "vuetify-selection-controls",
              component: () =>
                import("@/view/pages/vuetify/forms/SelectionControls.vue")
            },
            {
              path: "forms-selects",
              name: "vuetify-selects",
              component: () => import("@/view/pages/vuetify/forms/Selects.vue")
            },
            {
              path: "forms-textareas",
              name: "vuetify-textareas",
              component: () =>
                import("@/view/pages/vuetify/forms/Textareas.vue")
            },
            {
              path: "forms-text-fields",
              name: "vuetify-text-fields",
              component: () =>
                import("@/view/pages/vuetify/forms/TextFields.vue")
            },
            {
              path: "tables-simple-tables",
              name: "vuetify-simple-tables",
              component: () =>
                import("@/view/pages/vuetify/tables/SimpleTables.vue")
            },
            {
              path: "tables-data-tables",
              name: "vuetify-data-tables",
              component: () =>
                import("@/view/pages/vuetify/tables/DataTables.vue")
            },
            {
              path: "tabs",
              name: "vuetify-tabs",
              component: () => import("@/view/pages/vuetify/Tabs.vue")
            },
            {
              path: "timelines",
              name: "vuetify-timelines",
              component: () => import("@/view/pages/vuetify/Timelines.vue")
            },
            {
              path: "tooltips",
              name: "vuetify-tooltips",
              component: () => import("@/view/pages/vuetify/Tooltips.vue")
            },
            {
              path: "treeview",
              name: "vuetify-treeview",
              component: () => import("@/view/pages/vuetify/Treeview.vue")
            }
          ]
        },
        {
          path: "/custom-wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/view/pages/wizard/Wizard-1.vue")
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/view/pages/wizard/Wizard-2.vue")
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/view/pages/wizard/Wizard-3.vue")
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/view/pages/wizard/Wizard-4.vue")
            }
          ]
        },
        {
          path: "/custom-plugins",
          name: "plugins",
          component: () => import("@/view/pages/plugins/Plugins.vue"),
          children: [
            {
              path: "cropper",
              name: "cropper",
              component: () => import("@/view/pages/plugins/Cropper.vue")
            },
            {
              path: "treeselect",
              name: "treeselect",
              component: () => import("@/view/pages/plugins/Treeselect.vue")
            }
          ]
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "profile-1",
              name: "profile-1",
              component: () => import("@/view/pages/profile/Profile-1.vue")
            },
            {
              path: "profile-2",
              name: "profile-2",
              component: () => import("@/view/pages/profile/Profile-2.vue")
            },
            {
              path: "profile-3",
              name: "profile-3",
              component: () => import("@/view/pages/profile/Profile-3.vue")
            },
            {
              path: "profile-4",
              name: "profile-4",
              component: () => import("@/view/pages/profile/Profile-4.vue")
            }
          ]
        }
      ]
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-2"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-2")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-2")
        },
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    },
    {
      path: "/email-verification",
      name: "Email Verification",
      component: () => import("@/view/pages/EmailVerification")
    },
  ]
});