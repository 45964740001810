<template>
  <div id="lom-data">
    <v-app-bar
      color="primary"
      dense
      dark
      elevate-on-scroll
      scroll-target="#scrolling-techniques-7"
    >
      <v-toolbar-title>
        {{ PageTitle }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn icon dark @click="closePrompt">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container v-if="!ResultFlag && !LoadingFlag">
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h5 class="text-center text-danger">No records found.</h5>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="ComingSoonFlag && !LoadingFlag">
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h5 class="text-center text-danger">Coming soon</h5>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="ResultFlag && !ComingSoonFlag">
          <v-row wrap>
            <v-col cols="12" sm="6" lg="6" md="6">
              <h5 class="blue--text text--lighten">Total Extension LO</h5>
              <p class="preview-content">{{ row.LomsCount }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="6" md="6">
              <h5 class="blue--text text--lighten">
                Total Foundation Contribution
              </h5>
              <p class="preview-content">{{ row.FoundationAmount }}</p>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" md="12" align="center">
              <h5 class="blue--text text--lighten">
                {{ tableOptions1.TableTitle }}
              </h5>
              <h6 class="text-danger" v-if="tableData1.length == 0">
                No records found.
              </h6>
            </v-col>
          </v-row>
          <v-row wrap v-if="tableData1.length > 0">
            <v-col cols="12" md="12" align="center">
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected1"
                :search="search1"
                :loading="tableLoadingFlag1"
                :headers="tableColumns1"
                :items="tableData1"
                :show-select="tableOptions1.ShowSelectFlag"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
              </v-data-table>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" md="12" align="center">
              <h5 class="blue--text text--lighten">
                {{ tableOptions2.TableTitle }}
              </h5>
              <h6 class="text-danger" v-if="tableData2.length == 0">
                No records found.
              </h6>
            </v-col>
          </v-row>
          <v-row wrap v-if="tableData2.length > 0">
            <v-col cols="12" md="12" align="center">
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected2"
                :search="search2"
                :loading="tableLoadingFlag2"
                :headers="tableColumns2"
                :items="tableData2"
                :show-select="tableOptions2.ShowSelectFlag"
                :item-key="tableOptions2.ItemKey"
                :single-select="tableOptions2.SingleSelectFlag"
                :items-per-page="tableOptions2.ItemsPerPage"
                :footer-props="tableOptions2.FooterProps"
              >
              </v-data-table>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col cols="12" md="12" align="center">
              <h5 class="blue--text text--lighten">
                {{ tableOptions3.TableTitle }}
              </h5>
              <h6 class="text-danger" v-if="tableData3.length == 0">
                No records found.
              </h6>
            </v-col>
          </v-row>
          <v-row wrap v-if="tableData3.length > 0">
            <v-col cols="12" md="12" align="center">
              <v-data-table
                class="elevation-1"
                loading-text="Loading... Please wait"
                v-model="selected3"
                :search="search3"
                :loading="tableLoadingFlag3"
                :headers="tableColumns3"
                :items="tableData3"
                :show-select="tableOptions3.ShowSelectFlag"
                :item-key="tableOptions3.ItemKey"
                :single-select="tableOptions3.SingleSelectFlag"
                :items-per-page="tableOptions3.ItemsPerPage"
                :footer-props="tableOptions3.FooterProps"
              >
                <template v-slot:item.Growth="{ item }">
                  <v-chip :color="item.Color" draggable
                    ><b class="text-white"> {{ item.Growth }} </b></v-chip
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";

export default {
  mixins: [common],
  props: {
    PageTitle: {
      type: String,
      required: true,
    },
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      row: {},
      ResultFlag: false,
      LoadingFlag: false,

      ComingSoonFlag: false,

      tableData1: [],
      tableColumns1: [],
      tableOptions1: {},
      selected1: [],
      search1: "",
      tableLoadingFlag1: false,

      LomCode: "",
      UserInterface: "",

      tableData2: [],
      tableColumns2: [],
      tableOptions2: {},
      selected2: [],
      search2: "",
      tableLoadingFlag2: false,

      tableData3: [],
      tableColumns3: [],
      tableOptions3: {},
      selected3: [],
      search3: "",
      tableLoadingFlag3: false,
    };
  },
  computed: {},
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var tr = this.recordData;
      console.log({ tr });
      this.UserInterface = tr.UserInterface;
      this.LomCode = tr.LomCode;
      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var message = "";

      var LomCode = this.LomCode;
      console.log({ LomCode });

      if (LomCode != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/lom/year-data";
        var upload = {
          UserInterface: this.UserInterface,
          Lom: LomCode,
        };
        console.log(add_url);
        console.log({ upload });

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.row = {};
        this.ResultFlag = false;
        this.LoadingFlag = true;
        this.ComingSoonFlag = false;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log({ flag });
            console.log({ output });
            console.log({ records });

            thisIns.ResultFlag = flag;
            thisIns.ComingSoonFlag = records.ComingSoonFlag;

            if (flag == 1) {
              thisIns.row = records;
              thisIns.tableOptions1 = records.TableOptions1;
              thisIns.tableColumns1 = records.TableHeader1;
              thisIns.tableData1 = records.TableData1;

              thisIns.tableOptions2 = records.TableOptions2;
              thisIns.tableColumns2 = records.TableHeader2;
              thisIns.tableData2 = records.TableData2;

              thisIns.tableOptions3 = records.TableOptions3;
              thisIns.tableColumns3 = records.TableHeader3;
              thisIns.tableData3 = records.TableData3;
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
          });
      } else {
        message = "LOM details not found. ";
        console.log("error=" + message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#lom-data {
  .preview-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5em;
  }
}
</style>