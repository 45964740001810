<template>
  <div>
    <v-row wrap v-if="LoadingFlag">
      <v-col cols="12" md="4" v-for="n in 6" :key="n">
        <v-skeleton-loader v-bind="attrs" type="paragraph"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    LoadingFlag: {
      required: true,
    },
  },
  data() {
    return {
      attrs: {
        // class: "mb-6",
        // boilerplate: true,
        // elevation: 2,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>